import { getAuthToken } from "../context/auth-context";
import { CALL_API } from "../context/constValues";
import Swal from "sweetalert2";

export async function getAllUsuario() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/usuario`, {
    headers: { Authorization: "Bearer " + token },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("No se encontro informacion");
  }
  return data.result;
}

export async function getUsuarioById(idUsuario) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/usuario/${idUsuario}`, {
    headers: { Authorization: "Bearer " + token },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error("No se encontro informacion");
  }
  return data.result;
}

export async function getUsuarioByTipoUsuario(idTipoUsuario) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/usuario-tipo/${idTipoUsuario}`, {
    headers: { Authorization: "Bearer " + token },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message ? data.message : "Error");
  }
  return data.result;
}
export async function getUserAsignaciones(idUsuario) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/asignacion/Verasignaciones`, {
    headers: { Authorization: "Bearer " + token },
  });
  const data = await response.json();
  console.log(data);
  if (!response.ok) {
    throw new Error("No se encontro informacion");
  }
  return data.result;
}

export async function getUserCredentials() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/user-credentials`, {
    headers: { Authorization: "Bearer " + token },
  });
  const data = await response.json();
  if (!response.ok) {
    console.error("no se encontro datos");
  }
  return data.userData;
}

export async function signUp(userObject) {
  const {
    Fechadenacimiento,
    RegistroProfesional,
    apellido,
    cedula,
    ciudad,
    direccion,
    nombre,
    password,
    subtipo,
    telefono,
    tipousuario,
    usuario,
    imagen,
    Fechadeingreso,
    sexo,
    estadocivil,
    hijos,
    antpol,
    departamento,
    barrio,
  } = userObject;
  const nombrecompleto = nombre + " " + apellido;
  const token = getAuthToken();
  const formData = new FormData();
  formData.append("Fechadenacimiento", Fechadenacimiento);
  formData.append("RegistroProfesional", RegistroProfesional);
  formData.append("apellido", apellido);
  formData.append("cedula", cedula);
  formData.append("ciudad", ciudad);
  formData.append("direccion", direccion);
  formData.append("nombre", nombrecompleto);
  formData.append("password", password);
  formData.append("subtipo", subtipo);
  formData.append("telefono", telefono);
  formData.append("tipousuario", tipousuario);
  formData.append("usuario", usuario);
  formData.append("Fechadeingreso", Fechadeingreso);
  formData.append("sexo", sexo);
  formData.append("estadocivil", estadocivil);
  formData.append("hijos", hijos);
  formData.append("antpol", antpol);
  formData.append("departamento", departamento);
  formData.append("barrio", barrio);
  formData.append("image", imagen);
  const response = await fetch(`${CALL_API}/usuario`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
    body: formData,
  });

  const data = await response.json();

  if (!response.ok) {
    Swal.fire({
      title: "Error!",
      text: `${data.messege}`,
      icon: "error",
      confirmButtonText: "Ok",
    });
    throw new Error(`${data.messege}`);
  }

  return data;
}

export async function updateUsuario(usuarioObject) {
  const {
    Usuario,
    tipousuario,
    subtipo,
    Fechaingreso,
    RegistroProfesional,
    Nombre,
    Cedula,
    sexo,
    estadocivil,
    hijos,
    Fechadenacimiento,
    antpol,
    Telefono,
    departamento,
    Ciudad,
    Direccion,
    IdUsuario,
    imagen,
    barrio,
    imgvalue
  } = usuarioObject;
  console.log(usuarioObject)
  const token = getAuthToken();
  const formData = new FormData();
  formData.append("Fechadenacimiento", Fechadenacimiento);
  formData.append("registroprofesional", RegistroProfesional);
  formData.append("Cedula", Cedula);
  formData.append("Ciudad", Ciudad);
  formData.append("Direccion", Direccion);
  formData.append("Nombre", Nombre);
  formData.append("subtipo", subtipo);
  formData.append("Telefono", Telefono);
  formData.append("tipousuario", tipousuario);
  formData.append("Usuario", Usuario);
  formData.append("Fechaingreso",Fechaingreso);
  formData.append("sexo", sexo);
  formData.append("estadocivil", estadocivil);
  formData.append("hijos", hijos);
  formData.append("antpol", antpol);
  formData.append("departamento", departamento);
  formData.append("IdUsuario", IdUsuario);
  formData.append("imagen", imagen);
  formData.append("barrio", barrio);
  formData.append("image",imgvalue);
  const response = await fetch(`${CALL_API}/updateusuario`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
    body: formData,
  });

  const data = await response.json();

  if (!response.ok) {
    Swal.fire({
      title: "Error!",
      text: `${data.messege}`,
      icon: "error",
      confirmButtonText: "Ok",
    });
    throw new Error(`${data.messege}`);
  }

  return data;
}

export async function resetPassword(usuarioObject) {
  console.log(usuarioObject)
  const { contraseña1, IdUsuario } = usuarioObject;
  console.log(IdUsuario)
  const token = getAuthToken();
  const result = await fetch(`${CALL_API}/reset/${IdUsuario}`, {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      newPassword: contraseña1,
      IdUsuario:IdUsuario
    }),
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message || "No se pudo resetear la contraseña");
  }

  return data.isReset;
}

//cambio
export async function login(usuarioObject) {
  const { usuario, password } = usuarioObject;
  const response = await fetch(`${CALL_API}/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      usuario: usuario,
      password: password,
    }),
  });

  const data = await response.json();
  console.log(data)
  if (!response.ok) {
    Swal.fire({
      title: "Error!",
      text: `${data.message}`,
      icon: "error",
      confirmButtonText: "Ok",
    });
    
  }

  return data;
}

export async function DeleteUsuario(idUsuario) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/usuario/${idUsuario}`, {
    method: "DELETE",
    headers: { Authorization: "Bearer " + token },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("No se pudo eliminar");
  }
  return data.isDeleted;
}
export async function EstadoUsuario(usuarioObject) {
  const {IdUsuario,motivo} = usuarioObject;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/updateEstado`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idUsuario:IdUsuario,
      motivo:motivo,
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    Swal.fire({
      title: "Error!",
      text: `${data.messege}`,
      icon: "error",
      confirmButtonText: "Ok",
    });
    throw new Error("Can not sign up this user");
  }
  return data;
}
